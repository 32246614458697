import { graphql, useStaticQuery } from 'gatsby';

export const CMSSiteConfigurationQuery = graphql`
  query {
    file(sourceInstanceName: { eq: "site_configuration" }) {
      childMdx {
        frontmatter {
          menu_items {
            link
            name
          }
        }
      }
    }
  }
`;

export default function useCMSSiteConfiguration() {
  const data = useStaticQuery(CMSSiteConfigurationQuery);

  return data.file.childMdx.frontmatter;
}
