import { useStaticQuery, graphql } from 'gatsby';
import React from 'react';
import { ThemeProvider } from 'styled-components';

import theme from '../styles/theme';
import GlobalStyle from '../styles/global-styles';
import Header from '../components/Header';

interface LayoutProps {
  currPage: string;
}

const Layout: React.FC<LayoutProps> = function (props) {
  const { children, currPage } = props;

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <div>
          <Header currPage={currPage} />
          <main>{children}</main>
        </div>
      </ThemeProvider>
    </>
  );
};

export default Layout;
