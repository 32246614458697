import React from 'react';

import Nav from './shared/Nav';
import useCMSSiteConfiguration from '../hooks/useCMSSiteConfiguration';

interface HeaderProps {
  currPage: string;
}

const Header: React.FC<HeaderProps> = props => {
  const { currPage } = props;
  const { menu_items } = useCMSSiteConfiguration();

  return <Nav menuItems={menu_items} currPage={currPage} />;
};

export default Header;
