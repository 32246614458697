/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import Helmet from 'react-helmet';
import { resolve } from 'url';
import { useStaticQuery, graphql } from 'gatsby';

interface Props {
  description?: string;
  title: string;
  lang?: string;
  meta?: object[];
}

const SEO: React.FC<Props> = function (props) {
  const { description, lang = 'en', meta = [], title } = props;

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  const jsonLDSchema = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'Courtney Barnett',
    description: metaDescription,
    url: site.siteMetadata.siteUrl,
    logo: resolve(site.siteMetadata.siteUrl, 'favicon.png'),
    sameAs: [
      // Some social media links
    ],
    contactPoint: {
      '@type': 'ContactPoint',
      email: 'hello@example.com',
    },
  };

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: `${site.siteMetadata.title}`,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: 'og:image',
          content: resolve(site.siteMetadata.siteUrl, 'og.jpg'),
        },
        {
          property: 'og:url',
          content: site.siteMetadata.siteUrl,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: 'twitter:image',
          content: resolve(site.siteMetadata.siteUrl, 'og.jpg'),
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: 'twitter:url',
          content: site.siteMetadata.siteUrl,
        },
        {
          name: `twitter:title`,
          content: `${site.siteMetadata.title}`,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <script type="application/ld+json">{JSON.stringify(jsonLDSchema)}</script>
    </Helmet>
  );
};

export default SEO;
